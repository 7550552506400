import React from "react"

import Banner from "../components/banner/banner"
import PageBody from "../components/page-body/pageBody"
import bg from "../images/bg-2.jpeg"

import foodBankMap from "../images/components/pantry-info-last-updated-sep-2021.jpg"

import BorderTitle from "../components/border-title/borderTitle"
import Accordion from "./../components/accordion/accordion"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBook,
  faBrain,
  faAppleAlt,
  faCompass,
} from '@fortawesome/free-solid-svg-icons'
//import '@fortawesome/fontawesome-svg-core/styles.css'

import "./resources.scss"
import "../components/alert/alert.css"
import { resourcePage } from "../utils/text"
import { faqPage } from "../utils/text"

//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.min.js";

// Old FAQ
// <pre>{faqPage.additionalInformation.body} <span><a target="_blank" href={faqPage.additionalInformation.link.url}>{faqPage.additionalInformation.link.label}</a></span></pre>
       

var resources = props => (
  <>
    <div className="resources">
      <Banner
        src={bg}
        title={resourcePage.banner.title} 
        height="20rem"
      />


    <PageBody className="page">

    {/* Stacked Icon Idea
    <br></br>
    <div className="center-icon">
      <div className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faCircle} color="#2f80a4" size="5x" />
        <FontAwesomeIcon icon={faBook}  inverse transform="shrink-6 right-2" size="4x" />
      </div>
    </div>
    <br></br>
    <br></br>
    */}

      {/* Booklet Info Resources*/}
      <BorderTitle >
        <FontAwesomeIcon icon={faBook} size="1x" /> &nbsp;
        {resourcePage.bookletResources.title} 
      </BorderTitle>

      {faqPage.additionalInformation.body}
      <span><a target="_blank" href={faqPage.additionalInformation.link.url}>{faqPage.additionalInformation.link.label}</a></span>
              
      <hr />

      {/* Mental Health Resources*/}
      <div className="pad-top-1">
        <BorderTitle >
          <FontAwesomeIcon icon={faBrain} size="1x" /> &nbsp;
          {resourcePage.mentalHealthResources.title} 
        </BorderTitle>

        <p>{resourcePage.mentalHealthResources.text} </p>
        <a href={resourcePage.mentalHealthResources.url} target="_blank">
            Download the Resource Info</a>
      </div>

      <hr />

      {/* Food Pantry FAQ  */}     
      <div className="pad-top-1">
        <div className="faqs">
          <BorderTitle >
            <FontAwesomeIcon icon={faAppleAlt} size="1x" /> &nbsp;
            {resourcePage.churchPantryResources.foodPantryQA.categoryTitle}
          </BorderTitle>

          <div className="pad-all-5">
              <div class="alert alert-info" role="alert">
                {resourcePage.infoAlerts.alert1}
              </div>
          </div>

            <Accordion {...resourcePage.churchPantryResources.foodPantryQA.q1} />
            <Accordion {...resourcePage.churchPantryResources.foodPantryQA.q2} />
            <Accordion {...resourcePage.churchPantryResources.foodPantryQA.q3} /> 
        </div>
      </div> 

      {/* Food Pantry Info */}
      <div className="pad-top-2rem">
        <BorderTitle>
          <FontAwesomeIcon icon={faCompass} size="1x" /> &nbsp;
          {resourcePage.churchPantryResources.title} 
        </BorderTitle>
      </div>

        <div className="body">
            <div>
                <img className="left" src={foodBankMap} alt="" id="Year3_FP_Map"/>
                <h4>{resourcePage.churchPantryResources.section1.title} </h4>
                <br />
                {resourcePage.churchPantryResources.section1.text}
            </div>

            <div className="pad-top-20">
              <div className="pad-top-1">
                <BorderTitle>
                  {resourcePage.churchPantryResources.section2.title}
                </BorderTitle>
              </div>

              <div className="pad-top-2">
                <div className="pad-all-5">
                  <div class="alert alert-danger" role="alert">
                    {resourcePage.churchPantryResources.section2.alert}
                  </div>
                </div>
              </div>

                <iframe 
                  src={resourcePage.churchPantryResources.section2.sheetURL}
                    width="1200" 
                    height="1000">
                </iframe>
            </div>

        </div>

    </PageBody>

    
    </div>
  </>
)

export default resources
